<template>
  <div>
    <el-card v-if="!didVote && activeVote !== null" class="card card--small">
      <h1 class="card__heading text-center">Wat wil jij horen?</h1>
      <p class="card__description text-center">
        Stem nu op één van onze medleys
      </p>
      <el-row :gutter="20">
        <el-col
          v-for="option in activeVote.options"
          :span="12"
          :key="option._id"
          style="margin-bottom: 20px"
        >
          <el-card :body-style="{ padding: '0px' }">
            <figure
              v-if="option.image_url"
              class="vote-option"
              @click="vote(option)"
            >
              <el-image
                :src="`${$store.getters.getConstant('API_URI')}/${
                  option.image_url
                }`"
                fit="cover"
                class="vote-option__image"
              >
                <template #placeholder>
                  <div class="image-slot">
                    <i class="el-icon-loading"></i>
                  </div>
                </template>
                <template #error>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                    <p>Kan foto niet laden</p>
                  </div>
                </template>
              </el-image>
              <figcaption class="vote-option__caption">
                {{ option.name }}
              </figcaption>
            </figure>
            <div v-else class="option-image-default" @click="vote(option)">
              <div class="vote-option__caption">{{ option.name }}</div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row>
        <router-link
          to="/"
          @click="saveVoteToLocalStorage"
          class="vote__back-link"
          >Ik sla deze stemronde liever over</router-link
        >
      </el-row>
    </el-card>

    <el-card
      v-else-if="activeVote === null"
      class="card card--small"
      style="text-align: right"
    >
      <h1 class="card__heading text-center">Helaas!</h1>
      <p class="card__description text-center">
        Je kunt op dit moment niet stemmen.
      </p>

      <el-button type="primary" @click="$router.push('/')">Afsluiten</el-button>
    </el-card>

    <el-card v-else class="card card--small" style="text-align: right">
      <h1 class="card__heading text-center">Bedankt voor je stem!</h1>
      <p class="card__description text-center">
        Hou onze schermen in de gaten voor de status van deze stemronde.
      </p>

      <el-button type="primary" @click="$router.push('/')">Afsluiten</el-button>
    </el-card>
  </div>
</template>

<script>
import fetchMixins from "../mixins/ajax";
import { ElImage } from "element-plus";
export default {
  name: "Vote",
  components: {
    ElImage,
  },
  mixins: [fetchMixins],
  data() {
    return {
      didVote: false,
    };
  },
  computed: {
    activeVote: {
      get: function () {
        return this.$store.getters.activeVote;
      },
      set: function (newVal) {
        this.$store.commit("setActiveVote", newVal);
      },
    },
  },
  methods: {
    async vote(option) {
      const response = await this.fetchData(
        null,
        `vote/add-vote/${option._id}`,
        "POST",
        null,
        null
      );

      if (response.statusCode !== 200) {
        this.$message(
          "Oeps, er is iets misgegaan. Probeer het later nog eens."
        );
        return;
      }
      this.saveVoteToLocalStorage();
      this.didVote = true;
    },
    saveVoteToLocalStorage() {
      localStorage.setItem("vote", this.activeVote.start_time);
    },
  },
  beforeRouteEnter(to, from, next) {
    // Route only accessible from '/' with passed allowedToVote param
    return to.params.allowedToVote ? next() : next("/");
  },
};
</script>

<style lang="scss" scoped>
.option-image-default {
  height: 15rem;
  position: relative;
}

::v-deep .el-row {
  & div:nth-of-type(1) div div div.option-image-default {
    background-color: $color-primary;
  }
  & div:nth-of-type(2) div div div.option-image-default {
    background-color: #e6a23c;
  }
  & div:nth-of-type(3) div div div.option-image-default {
    background-color: #909399;
  }
  & div:nth-of-type(4) div div div.option-image-default {
    background-color: #000;
  }
}

.vote-option {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  aspect-ratio: 1 / 1;

  &:hover {
    & .vote-option__image {
      transform: scale(1.1);
    }
  }

  &__image {
    opacity: 0.5;
    height: 100%;
    transition: transform 0.25s ease-out;
  }

  &__caption {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($color-dark-gray, 0.9);
    color: #fff;
    padding: 0.5rem 2rem;
    border-radius: 4px;
    text-align: center;
  }
}

.vote__back-link {
  color: $color-light-gray;
  display: inline-block;
  margin: auto;
  font-size: 1.2rem;
  text-decoration: none;
  transition: all 0.25s ease-out;

  &:hover {
    color: $color-primary;
  }
}
</style>
